import React, { FC } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Connect from "../components/common/research/Connect";
import ImprovesMemory from "../components/common/research/ImprovesMemory";
import VisualSpatial from "../components/common/research/VisualSpatial";
import DeepSurface from "../components/common/research/DeepSurface";
import MultidimensionalKnowledge from "../components/common/research/MultidimensionalKnowledge";
import Information from "../components/common/research/Information";
import AutonomousLearners from "../components/common/research/AutonomousLearners";
import Bibliography from "../components/common/research/Bibliography";
import LearnMore from "../components/common/research/LearnMore";
import Footer from "../components/sections/footer";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const WhyItWorksPage: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Research" />
        <Navigation />
        <Connect />
        <ImprovesMemory />
        <VisualSpatial />
        <DeepSurface />
        <MultidimensionalKnowledge />
        <Information />
        <AutonomousLearners />
        <Bibliography />
        <LearnMore />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default WhyItWorksPage;
