import React from "react";
import { Container } from "../../global";
import { FC } from "react";
import { Typography } from "@material-ui/core";
import { GridItem, GridContainer, Wrapper } from "../style";
import image from "../../../images/research/Artboard_4.svg";

const DeepSurface: FC = () => {
  return (
    <Wrapper id="deepSurface">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              DEEP VS. SURFACE LEARNING
            </Typography>
            <Typography variant="body1">
              Many people learn ideas through rote learning, where they can repeat the information word-for-word – but
              they can’t always explain it. The problem with this approach is that you need more than just
              ‘surface-level’ learning to truly understand information, and you need real understanding to put your
              knowledge to use (Mayer, 2002). Processing ideas more deeply is also associated with better memory
              (Lockhart et al., 1976).
            </Typography>
            <Typography variant="body1">
              Ako Maps help facilitate deep learning by helping you find the connections between ideas, instead of just
              learning facts in isolation. When you build your own map about a topic, you have to think hard about the
              structure. What does an idea relate to? What category is it in? Where does (or doesn’t) it fit? By asking
              yourself these questions, it helps you find valuable context around each idea, and process what those
              ideas really mean.
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default DeepSurface;
