import React from "react";
import { ContainerColored } from "../../global";
import { FC } from "react";
import { GridItem, GridContainer, WrapperColored, Link } from "../style";
import { Typography } from "@material-ui/core";
import image from "../../../images/getStarted/artboard_4.svg";

const LearnMore: FC = () => {
  return (
    <WrapperColored id="learnMore">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              INTERESTED IN LEARNING MORE?
            </Typography>
            <Typography variant="body1">
              You might be interested in our ‘learning to learn’ course,{" "}
              <Link href="/app/course/5ef5864daf5ed70845e76fb1/summary" target="_blank">
                Learn Like a Genius
              </Link>
              .
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default LearnMore;
