import React from "react";
import { ContainerColored } from "../../global";
import { FC } from "react";
import { GridItem, GridContainer, WrapperColored } from "../style";
import { Typography } from "@material-ui/core";
import image from "../../../images/research/Artboard_7.svg";

const AutonomousLearners: FC = () => {
  return (
    <WrapperColored id="autonomousLearners">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              GROWING INTO AUTONOMOUS LEARNERS
            </Typography>
            <Typography variant="body1">
              When you’re first learning a topic, it’s important to have guidance and instruction to teach you the ideas
              in the first place (Rosenshine, 2010). However, the more you learn, the more you’re able to put that
              knowledge into practice. Because of this, your learning journey should give you enough information to
              learn from, then give you the freedom to explore and problem-solve using those skills.
            </Typography>
            <Typography variant="body1">
              In Ako Maps, you can learn from your teacher’s maps, or from our public courses. As you grow and progress
              through your journey in a subject, you’ll be encouraged to build up your own map of the subject. By
              applying knowledge as soon as you learn it, you’ll get the chance to remember more, be more creative, and
              take ownership of your learning.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default AutonomousLearners;
