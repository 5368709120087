import React from "react";
import { Container } from "../../global";
import { FC } from "react";
import { Typography } from "@material-ui/core";
import { GridItem, GridContainer, Wrapper } from "../style";
import image from "../../../images/research/Artboard_2.svg";

const ImprovesMemory: FC = () => {
  return (
    <Wrapper id="improvesMemory">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              REGULAR RECALL IMPROVES MEMORY
            </Typography>
            <Typography variant="body1">
              ‘Recall’ means remembering something you learned. The problem is, it’s easy to think you know a topic well
              enough to recall it – when you’ve really just learned to recognise it. That’s why a textbook looks
              familiar when you re-read it, but it’s hard to remember during the test! For long-term learning, recall is
              more important than recognition memory.
            </Typography>
            <Typography variant="body1">
              The good news is that recalling information – that is, testing yourself – will actually improve your
              memory (Roediger et al., 2006). In fact, regular recall practice is one of the most effective ways to
              learn (Dunlowsky et al., 2013). Ako Maps make it easy to practice your recall. You can click a button to
              turn the information in your Ako Map into ‘automatic flashcards’, with the idea on one side and its
              details on the other. You can also see what points you remember from your map, then get instant feedback
              on how much you remembered, what you missed, and how long it took.
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default ImprovesMemory;
